<template>
  <div class="Factory MERCH">
    <div class="merch-card">
      <!-- 搜索列 -->
      <div class="merch-search">
        <div class="search-box">
          <!-- 审核状态 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">厂家资质:</div> -->
            <el-input
              placeholder="厂家资质"
              v-model="UsersApi.keyWords"
            ></el-input>
          </div>

          <!-- 搜索 -->
          <div class="sch-1 sch-2">
            <el-button type="primary" size="mini" @click="search">
              查 询
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="openAdd"
              v-if="allJurisdiction.Factory84"
            >
              新建
            </el-button>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div
        :style="`height: ` + mainHright + `px`"
        class="merch-table scorr-roll"
      >
        <div>
          <el-table :data="usersData.data" border style="width: 100%">
            <el-table-column label="序号" align="center">
              <template slot-scope="{ row }">
                <div class="exceed">{{ row.id }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="证明材料" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.name"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.name }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="排序" align="center">
              <template slot-scope="{ row }">
                <div class="exceed">{{ row.sort }}</div>
              </template>
            </el-table-column>

            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="200"
            >
              <template slot-scope="{ row }">
                <div class="userBtn">
                  <!--  -->
                  <div @click="openAdd(row)" v-if="allJurisdiction.Factory85">
                    编辑
                  </div>

                  <!-- <div @click="toMessage(row)">删除</div> -->
                  <el-popconfirm
                    @confirm="toMessage(row)"
                    title="确定删除当前厂家材料？删除后用户将无法选择。"
                  >
                    <div slot="reference" v-if="allJurisdiction.Factory86">
                      删除
                    </div>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :page-size="usersData.per_page"
          layout="total, prev, pager, next, jumper"
          :total="usersData.total"
          :current-page="UsersApi.page"
        >
        </el-pagination>
      </div>
      <!-- 新建编辑 -->
      <div>
        <el-dialog title="材料信息" :visible.sync="islogistics" width="600px">
          <div>
            <el-form ref="form" label-width="100px" :rules="rules">
              <el-form-item label="资质名称" prop="name">
                <el-input
                  v-model="AddData.name"
                  placeholder="资质名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="排序" prop="sort">
                <el-input v-model="AddData.sort" placeholder="排序"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="islogistics = false">取 消</el-button>
            <el-button type="primary" @click="afiyAdd">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Basics"); //vuex公共库
export default {
  data() {
    return {
      UsersApi: {
        keyWords: "", // 单位名称 法人 手机号关键字筛选
        page: 1,
      },
      usersData: {},
      AddData: {
        sort: 0,
      },
      islogistics: false,
      rules: {
        name: [{ required: true, message: "请输入资质名称", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
    };
  },

  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
    ]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.UsersApi = this.detailsSeach[router];
    }
    this.toSearch();
  },
  methods: {
    ...mapActions([
      "getfactorylist",
      "postfactoryadd",
      "postfactoryedit",
      "postfactorydelete",
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    // 新增编辑确认
    async afiyAdd() {
      if (!this.AddData.name) {
        this.$message({
          message: "资料未填写完整",
          type: "warning",
        });
        return;
      }
      let data = {};

      if (this.AddData.id) {
        data = await this.postfactoryedit(this.AddData);
      } else {
        data = await this.postfactoryadd(this.AddData);
      }
      if (data.code == 200) {
        this.toSearch();
        this.islogistics = false;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 打开新建弹框
    openAdd(row) {
      if (!row.id) {
        this.AddData = {};
        this.islogistics = true;
      } else {
        this.AddData = row;
        this.islogistics = true;
      }
    },
    handleCurrentChange(e) {
      this.UsersApi.page = e;
      this.toSearch();
    },
    search(){
      this.UsersApi.page = 1;
      this.toSearch();
    },
    async toSearch() {
      let data = await this.getfactorylist(this.UsersApi);

      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.UsersApi };
      save.cascaderValue = this.cascaderValue;
      save.router = router;
      this.changeSeach(save);

      this.usersData = data.data;
    },
    // 前往详情
    async toMessage(row) {
      let data = await this.postfactorydelete({ id: row.id });
      if (data.code == 200) {
        this.toSearch();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
      // this.$router.push({
      //   path: "/usersMessage",
      //   query: { user_id: row.user_id, id: row.ID },
      // });
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  // .el-input {
  //   width: 300px !important;
  // }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
</style>
